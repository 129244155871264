<template>
  <div class="menu-mobile__navbar">
    <Search
      v-if="search && isSearchEnabled"
      :search-results="searchResults"
      :search-value="searchValue"
      @disable:search="emit('disable-search')"
      @search="emit('search', $event)"
      @submit:search="emit('submit-search', $event)"
      @show:suggestions="emit('show-suggestions', $event)"
      @open:course="
        (courseId, environment) => $emit('open-course', courseId, environment)
      "
    />
    <template v-else>
      <button
        class="menu-mobile__navbar-close"
        type="button"
        @click="popupStore.closeMenu"
      >
        <img src="../../assets/x-1.svg" />
      </button>
      <div class="menu-mobile__navbar-label">
        <router-link
          class="menu-mobile__navbar-brand"
          :to="{ name: ROUTE.Home }"
          :title="$t('common.home')"
        >
          <img src="../../assets/menu/hl-logo.svg" alt="hl-logo" />
        </router-link>
        <template v-if="!isShopEnabled">
          <router-link
            class="menu-mobile__navbar-brand"
            :to="{ name: ROUTE.Home }"
            :title="$t('common.home')"
          >
            <img src="../../assets/menu/lsmu-logo.svg" alt="lsmu-logo" />
          </router-link>
        </template>
      </div>
      <a v-if="search" href="#" @click.prevent="emit('toggle-search', $event)">
        <img :src="SearchBlue" alt="search" />
      </a>
      <Avatar
        v-else-if="auth2Store.isLoggedIn"
        :image="settingsStore.values.image"
        :account="accountStore.values"
        @click.prevent="popupStore.openMenuSettings"
      />
    </template>
  </div>
</template>

<script setup>
import { isShoppingEnabled } from "@/util/helpers";
import { computed, defineEmits, defineProps } from "vue";
import { ROUTE } from "@/router/routenames";
import { useStores } from "@/store/use.store";
import Search from "@/components/Search.vue";
import SearchBlue from "@/assets/menu/search-blue.svg";
import Avatar from "@/components/Avatar.vue";

defineProps({
  search: { type: Boolean, default: false },
  isSearchEnabled: { type: Boolean, default: false },
  searchResults: { type: Array, default: () => [] },
  searchValue: { type: String, default: "" },
});

const emit = defineEmits([
  "disable-search",
  "search",
  "submit-search",
  "show-suggestions",
  "open-course",
  "toggle-search",
]);

const { popupStore, auth2Store, settingsStore, accountStore } = useStores();

const isShopEnabled = computed(() => isShoppingEnabled());
</script>

<style lang="scss" scoped>
.menu-mobile__navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 20px;

  &-close {
    border: none;
    background: transparent;
    padding: 0;
  }

  &-label {
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
    font-weight: 500;
    font-size: $font-size-lg;
  }

  &-link--icon {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;

    & > img {
      margin-right: 0;
    }
  }

  &-brand {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 2px 0;

    img {
      height: 52px;
    }
  }
}
</style>
