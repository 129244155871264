import { defineStore } from "pinia";
import axios from "axios";
import { useAlertStore } from "./alert.store";
import { lastValueFrom, Subject } from "rxjs";
import router from "@/router";
import i18n from "@/i18n";
import { useModalStore } from "@/store/modules/modal.store";
import KeepShoppingOrCart from "@/components/modals/KeepShoppingOrCart.vue";
import { ROUTE } from "@/router/routenames";

let __GET_CART_SUBJECT_ = null;

export const useCartStore = defineStore({
  id: "cart",
  state: () => ({
    loading: false,
    cart: null,
    payment: null,
    select: null,
    company: null,
  }),
  getters: {
    totalCount: (state) => ((state.cart || {}).total_raw || 0).toFixed(2),
    products: (state) => (state.cart || {}).products || [],
    voucher: (state) => (state.cart || {}).voucher || "",
    coupon: (state) => (state.cart || {}).coupon || "",
    discount() {
      return this.voucher || this.coupon;
    },
    count() {
      return this.products.length;
    },
    isEmpty() {
      return this.count == 0;
    },
    totals: (state) => (state.cart || {}).totals || [],
    totalsIsEmpty() {
      return this.totals.length == 0;
    },
    paymentMethods: (state) => (state.payment || {}).payment_methods || [],
    firstMethod() {
      return (this.paymentMethods[0] || {}).code || null;
    },
    enablePayment: (state) => !!state.select,
    companyInfo: (state) => state.company || {},
  },
  actions: {
    async getItems() {
      if (__GET_CART_SUBJECT_) {
        return lastValueFrom(__GET_CART_SUBJECT_);
      } else {
        __GET_CART_SUBJECT_ = new Subject();
        const result = await this.doGetItems();

        __GET_CART_SUBJECT_.next(result);
        __GET_CART_SUBJECT_.complete();
        __GET_CART_SUBJECT_ = null;

        return result;
      }
    },
    async doGetItems() {
      this.loading = true;
      try {
        const { data } = await axios.get("/cart");

        this.loading = false;

        if (data.success) {
          this.cart = data.data;

          return true;
        } else {
          this.cart = null;
          console.error(data.error);

          return false;
        }
      } catch (error) {
        console.error(error);

        this.loading = false;
        this.cart = null;

        return false;
      }
    },
    async remove(key) {
      const alertStore = useAlertStore();
      let vm = this;
      await axios
        .delete(`/cart/${key}`)
        .then(async (response) => {
          if (response.data.success === 1) {
            await vm.getItems();

            alertStore.success("Pavyko", "Produktas sėkmingai pašalintas");

            if (vm.totalsIsEmpty) {
              vm.removeDiscount();
            }
          }
        })
        .catch(console.error);
    },
    async add(product_id, quantity) {
      const alertStore = useAlertStore();
      const modalStore = useModalStore();
      await axios
        .post("/cart", {
          product_id,
          quantity,
        })
        .then((response) => {
          if (response.data.success == 1) {
            // this.items.push(response.data.product);
            alertStore.success(
              i18n.global.t("common.success"),
              i18n.global.t("common.add_to_cart_success"),
            );
            modalStore.create({
              component: KeepShoppingOrCart,
            });
            modalStore.open();
          } else {
            alertStore.error("Klaida", response.data.error[0]);
          }
        });

      await this.getItems();
    },
    async getPaymentMethods() {
      this.loading = true;
      try {
        const { data } = await axios.get("/paymentmethods");

        this.loading = false;
        if (data.success) {
          this.payment = data.data;

          return true;
        } else {
          this.payment = null;

          return false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;

        return false;
      }
    },
    async selectPaymentMethod(payment_method) {
      this.loading = true;
      try {
        const { data } = await axios.post(
          "/paymentmethods",
          {
            payment_method,
            agree: 1,
            comment: "...",
          },
          // if this fails we retry in component and then we'll get payment error anyways
          { skipError: true },
        );

        this.loading = false;
        if (data.success) {
          this.select = data.data;

          return true;
        } else {
          console.error(data.error);
          this.select = null;

          return false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.select = null;

        return false;
      }
    },
    async setCompanyInfo(form) {
      this.loading = true;
      try {
        const { data } = await axios.post("company", {
          ...form,
          private: false,
        });

        this.loading = false;
        if (data.success) {
          return true;
        }

        console.error(data.error);
        return false;
      } catch (error) {
        console.error(error);
        this.loading = false;

        return false;
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        const { data } = await axios.get("company");

        this.loading = false;
        if (data.success) {
          this.company = data.data;
          return true;
        }

        this.company = null;
        console.error(data.error);
        return false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.company = null;

        return false;
      }
    },
    async unsetCompanyInfo() {
      this.loading = true;
      try {
        const { data } = await axios.post("company", { private: true });

        this.loading = false;
        if (data.success) {
          return true;
        }

        console.error(data.error);
        return false;
      } catch (error) {
        console.error(error);
        this.loading = false;

        return false;
      }
    },
    async pay() {
      this.loading = true;
      try {
        const hash = process.env.VUE_APP_HISTORY === "hash" ? "#" : "";
        const productId = this.products[0].model;
        const routeData = router.resolve({
          name: ROUTE.Course.General,
          params: { id: productId },
        });
        const callback = window.location.href.replace(
          hash + "/cart/payment",
          routeData.href,
        );
        const { data: confirm } = await axios.post(
          "/confirm",
          {},
          { params: { success_callback: callback } },
        );

        if (confirm.success) {
          if (confirm.data.redirect) {
            await axios.get(confirm.data.redirect);

            return router.replace({
              name: ROUTE.Course.General,
              params: { id: productId },
            });
          }

          const { data: validate } = await axios.post(
            confirm.data.validate,
            {},
            { withCredentials: true },
          );

          if (validate.url) {
            window.location.href = validate.url;
          } else {
            this.loading = false;

            return false;
          }
        } else {
          console.error(confirm.error);
          this.loading = false;

          return false;
        }

        return true;
      } catch (error) {
        console.error(error);
        this.loading = false;

        return false;
      }
    },
    async removeDiscount() {
      this.loading = true;

      try {
        await axios.delete("/voucher");
      } catch (error) {
        console.error(error);
        try {
          await axios.delete("/coupon");
        } catch (error) {
          console.error(error);
        }
      } finally {
        this.loading = false;
        this.getItems();
      }
    },
  },
});
