<template>
  <Form>
    <div class="search-wrap">
      <img
        src="../../assets/icons/search-gray.svg"
        alt="search"
        @click="submit"
      />
      <input
        v-model="searchValue"
        name="q"
        :placeholder="placeholder || $t('navigation.search_placeholder')"
        @input="debouncedSearch"
        @keydown.enter.prevent="submit"
      />
      <button
        class="cancel"
        @click.prevent="clearSearch"
        v-text="$t('common.cancel')"
      />
    </div>
  </Form>
</template>

<script>
import { Form } from "vee-validate";
import debounce from "lodash.debounce";
import { useRoute } from "vue-router";

export default {
  components: { Form },
  props: {
    routeName: { type: String, default: null },
    placeholder: { type: String, default: null },
  },
  emits: ["submit:search", "disable:search", "search"],
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      searchValue: this.route.query.q,
    };
  },
  watch: {
    "route.query.q": {
      async handler() {
        this.searchValue = this.route.query.q;
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.searchCourses, 500);
  },
  methods: {
    searchCourses() {
      if (this.routeName) {
        this.$router.replace({
          name: this.routeName,
          query: { q: this.searchValue },
        });
      }
      this.$emit("search", this.searchValue);
    },
    clearSearch() {
      this.searchValue = "";
      if (this.routeName) {
        this.$router.replace({
          name: this.routeName,
        });
      }
      this.$emit("disable:search");
    },
    submit() {
      this.$emit("submit:search", this.searchValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  position: relative;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    left: 15px;
  }

  button {
    position: absolute;
    right: 4px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    border: 1px solid #dcdee9;
    border-radius: 24px;
    width: 100%;
    padding: 14px 40px;
  }

  .cancel {
    color: $color-menu-item;
    font-size: $font-size-xs;
    font-family: $font-family;
    padding-right: 30px;
  }
}
</style>
