<template>
  <div class="navbar__navbar-collapse">
    <ul class="navbar__nav">
      <li class="navbar__item navbar__item--dropdown">
        <LanguageToggle />
        <div class="navbar__dropdown-menu navbar__dropdown-menu--languages">
          <LanguageDropdown @change="changeLanguage" />
        </div>
      </li>
      <li
        v-if="isShoppingEnabled || auth2Store.isLoggedIn"
        class="navbar__item"
      >
        <a
          href="#"
          class="navbar__link navbar__link--active navbar__link__right"
          @click.prevent="emit('toggle-search', $event)"
        >
          <img :src="isDarkColor ? SearchWhite : SearchBlue" alt="search" />
        </a>
      </li>
      <li v-if="drakonhub" class="navbar__item">
        <a :href="drakonhub" target="_blank" class="navbar__link">
          <img
            :src="`${drakonhub}/static/drakosha.png`"
            style="max-height: 2rem"
          />
        </a>
      </li>
      <li v-if="isShoppingEnabled" class="navbar__item">
        <router-link
          class="navbar__link navbar__link__right navbar__link--active navbar__link--icon"
          :to="{ name: 'cart' }"
        >
          <div class="navbar__cart">
            <span v-if="!cartStore.isEmpty">
              {{ cartStore.count }}
            </span>
            <img :src="isDarkColor ? CartWhite : CartBlue" alt="cart" />
          </div>
        </router-link>
      </li>
      <li v-if="!auth2Store.isLoggedIn" class="navbar__item">
        <router-link
          :to="{ name: 'login' }"
          class="navbar__link navbar__link--active navbar__link__right"
        >
          <img src="../assets/avatar.svg" alt="login" />
        </router-link>
      </li>
      <li v-else class="navbar__item navbar__item--dropdown">
        <a
          class="navbar__link navbar__link--active navbar__link__right dropdown-toggle navbar__link--round"
          href="#"
          @click.prevent
        >
          <Avatar
            :image="settingsStore.values.image"
            :account="accountStore.values"
          />
        </a>
        <div class="navbar__dropdown-menu">
          <div class="navbar__profile-wrap">
            <div class="navbar__profile-block">
              <div class="navbar__profile-block-name">
                {{ accountStore.values.firstname }}
                {{ accountStore.values.lastname }}
              </div>
              <div
                v-if="settingsStore.values.username"
                class="navbar__profile-block-username"
              >
                {{ settingsStore.values.username }}
              </div>
            </div>
            <div v-if="isShoppingEnabled" class="navbar__profile-block">
              <router-link :to="{ name: 'dashboard' }">
                {{ $t("common.dashboard") }}
              </router-link>
            </div>
            <div v-if="isShoppingEnabled" class="navbar__profile-block">
              <router-link :to="{ name: ROUTE.Dashboard.Courses }">
                {{ $t("common.my_courses") }}
              </router-link>
            </div>
            <div v-if="isShoppingEnabled" class="navbar__profile-block">
              <router-link :to="{ name: 'dashboard.purchases' }">
                {{ $t("common.purchases_history") }}
              </router-link>
            </div>
            <div class="navbar__profile-block">
              <router-link :to="{ name: 'dashboard.settings' }">
                {{ $t("common.settings") }}
              </router-link>
            </div>
            <OldApp v-if="isShoppingEnabled || !auth2Store.isStudent" />
            <div class="navbar__profile-block">
              <div
                class="navbar__profile-block--logout"
                @click="auth2Store.logout"
              >
                <img src="../assets/menu/logout-red.svg" alt="logout" />
                <span>{{ $t("common.logout") }}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import LanguageToggle from "@/components/navbar/LanguageToggle.vue";
import LanguageDropdown from "@/components/navbar/LanguageDropdown.vue";
import Avatar from "@/components/Avatar.vue";
import SearchBlue from "@/assets/menu/search-blue.svg";
import SearchWhite from "@/assets/menu/search-white.svg";
import CartBlue from "@/assets/menu/cart-blue.svg";
import CartWhite from "@/assets/menu/cart-white.svg";
import { useStores } from "@/store/use.store";
import { ROUTE } from "@/router/routenames";
import OldApp from "@/components/navbar/OldApp.vue";
import { computed, defineEmits, defineProps } from "vue";

defineProps({
  isShoppingEnabled: { type: Boolean, required: true },
  changeLanguage: { type: Function, required: true },
  isDarkColor: { type: Boolean, required: true },
});

const emit = defineEmits(["toggle-search"]);

const drakonhub = computed(() => process.env.VUE_APP_DRAKONHUB || null);

const { auth2Store, cartStore, settingsStore, accountStore } = useStores();
</script>

<style scoped lang="scss">
.navbar__profile-block-name {
  color: $color-primary;
  font-family: $font-family;
  font-size: $font-size-slg;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.navbar__profile-block-username {
  color: $color-primary;
  font-family: $font-family;
  font-size: $font-size-md;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 400;
  margin-top: 8px;
}

.navbar__profile-block--logout {
  color: $color-light-coral;
  font-family: $font-family;
  font-size: $font-size-xs;
  letter-spacing: 0;
  line-height: 12px;
  font-weight: 400;
  cursor: pointer;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navbar__profile-block {
  a {
    display: block;
    padding: 16px 20px;

    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 15px;

    text-decoration: none;

    &.navbar__item--active {
      font-weight: 400 !important;
      color: $color-primary;
    }
  }

  box-shadow: inset 0 -1px 0 0 $color-light-grey;
}

.navbar__profile-block:first-child {
  padding: 26px 20px;
}

.navbar__profile-block:last-child {
  padding: 16px 20px;
}

.navbar__navbar-collapse {
  display: flex;
  align-items: center;
  gap: 20px;

  ul {
    padding-left: 0;
    list-style-type: none;
    display: flex;
  }
}

.navbar__item {
  display: flex;
}

.navbar__item--active {
  font-weight: bold;
}

.navbar__item--dropdown {
  position: relative;
  z-index: 9;
  min-height: 42px;

  @media screen and (max-width: $breakpoint-xl) {
    font-weight: bold;
  }
}

.navbar__item--dropdown:hover {
  .navbar__dropdown-menu {
    display: flex;
  }
}

.navbar__dropdown-menu {
  display: none;
  background: #ffffff;
  border-radius: 4px;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: -15px;
  width: 260px;
  box-shadow: 0 3px 15px 0 rgba(27, 33, 74, 0.15);
}

.navbar__dropdown-menu--languages {
  width: auto;
  padding: 10px;

  a {
    padding: 3px;
  }

  img {
    height: 26px;
    width: 26px;
  }
}

.navbar__link {
  padding: 5px 20px;
  color: $color-primary;
  font-family: $font-family;
  font-size: $font-size-xs;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 500;

  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;

  @media screen and (max-width: $breakpoint-lg) {
    opacity: 1;
  }
}

.navbar__link__right {
  padding: 5px 10px;
}

.navbar__link--round {
  img {
    border-radius: 30px;
  }
}

.navbar__link--icon {
  @media screen and (max-width: 900px) {
    padding: 5px 0;
  }

  img {
    margin-right: 10px;
  }
}

.navbar__link__cart {
  display: flex;

  span {
    position: absolute;
    top: -10px;
    right: 0;

    color: #ffffff;
    font-family: $font-family;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;

    height: 18px;
    width: 18px;
    background-color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 20px;
  }
}
</style>
