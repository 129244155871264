import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useProductsStore = defineStore("products", () => {
  const loading = ref(false);
  const loadingBySearch = ref(false);
  const products = ref(null);
  const current = ref(null);
  const productsBySearch = ref(null);

  // prettier-ignore
  const page = computed(() => products.value ? products.value.current_page : 1)
  // prettier-ignore
  const pageCount = computed(() => products.value ? products.value.last_page : 1)
  const total = computed(() => (products.value ? products.value.total : 0));
  const from = computed(() => (products.value ? products.value.from : 0));
  const to = computed(() => (products.value ? products.value.to : 0));

  const list = computed(() => (products.value ? products.value.data : []));

  const request = async (page = 1, limit = 12, query) => {
    try {
      loading.value = true;
      const { data } = await backService.get("products", {
        params: { ...query, page, limit },
      });

      loading.value = false;
      products.value = data;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const requestProductsBySearch = async (search) => {
    try {
      loadingBySearch.value = true;
      const { data } = await backService.get("products/summary", {
        params: { search },
      });

      loadingBySearch.value = false;
      productsBySearch.value = data;

      return true;
    } catch (error) {
      console.error(error);
      loadingBySearch.value = false;

      return false;
    }
  };

  const requestCurrent = async (id) => {
    try {
      loading.value = true;
      const { data } = await backService.get(
        `products/${encodeURIComponent(id)}`,
      );

      loading.value = false;
      current.value = data;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return {
    loading,
    loadingBySearch,
    products,
    list,
    current: computed(() => current.value),
    productsBySearch,

    page,
    pageCount,
    total,
    from,
    to,

    request,
    requestCurrent,
    requestProductsBySearch,
  };
});
