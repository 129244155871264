<template>
  <div class="filter-modal-wrapper">
    <SearchForm class="filter-modal-wrapper__search" :route-name="routeName" />
    <img src="../../assets/icons/filter-blue.svg" alt="Filter" @click="open" />
  </div>
  <FullPageModal
    v-show="isOpen"
    :name="'filters'"
    :toggle="true"
    @close="close()"
  >
    <template #body>
      <filter-content />
    </template>
  </FullPageModal>
</template>
<script setup>
import { computed, ref } from "vue";
import FilterContent from "@/components/filter/FilterContent.vue";
import FullPageModal from "@/components/FullPageModal.vue";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import SearchForm from "@/components/search/SearchForm.vue";

const isOpen = ref(false);

const routeName = computed(() =>
  isShoppingEnabled() ? ROUTE.Discover : ROUTE.Dashboard.Courses,
);

const close = () => (isOpen.value = false);
const open = () => (isOpen.value = true);
</script>
<style lang="scss" scoped>
.filter-modal-wrapper {
  border-top: 1px solid #e7e8ef;
  border-bottom: 1px solid #e7e8ef;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
  column-gap: 25px;

  &__search {
    width: 100%;
  }
}
</style>
